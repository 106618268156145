var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.story.content.component
    ? _c(_vm._f("dashify")(_vm.story.content.component), {
        key: _vm.story.content._uid,
        tag: "component",
        attrs: { blok: _vm.story.content }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }